import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'

const DobreCmentarzPage = ({ data }) => {
  console.log(data)
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Zabytkowy cmentarz w Dobrem" />
      <ReturnToPrevPage to={'/gminy/dobre'}>
        Materiały dotyczące gminy Dobre
      </ReturnToPrevPage>
      <Header1>Cmentarz</Header1>
      <MonumentContentWrapper>
        <p>
          Zapraszamy na wirtualny spacer na cmentarzu parafialnym w Dobrem. Na
          zdjęciach między innymi mogiła Jana Zycha, zasłużonego dla pamięci
          Konstantego Laszczki kierownika szkoły w Dobrem.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/dobre/cmentarz/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/dobre/cmentarz/" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default DobreCmentarzPage
